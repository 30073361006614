/* src/styles/Message.css */
.wrapper {
  display: flex;
  flex-direction: column;
  }
   
  .msg-class {
  margin-top: 15px;
  margin-bottom: 15px;
  /* padding-top: 1px; */
  /* padding-right: 5em; */
  }
   
  /* Add triangle pointer for user messages */
  .message.user::before {
  content: "";
  position: absolute;
  top: 15px; /* Adjusted to align with the bubble */
  right: -10px; /* Position it outside the bubble */
  display: block;
  border: 13px solid transparent; /* Make border transparent initially */
  border-left-color: #c7dbff; /* Color of the triangle for user messages */
  border-width: 18px 0 10px 14px; /* Create a triangle shape */
  transform: rotate(139deg); /* Rotate for a better angle */
  }
   
  .message.bot::before {
  content: "";
  position: absolute;
  top: 19px; /* Adjusted to align with the bubble */
  left: -7px; /* Position it outside the bubble */
  display: block;
  border: 13px solid transparent; /* Make border transparent initially */
  border-right-color:#a67fe8; /* Color of the triangle for bot messages */
  border-width: 20px 18px 2px 0; /* Create a triangle shape */
  transform: rotate(-148deg); /* Rotate for a better angle */
  }
   
  .message-time {
  position: absolute; /* Positioning relative to the parent */
  /*bottom: 40px;*/
  top: -14px;
  left: 15px; /* Align to the right */
  color: #043367; /* Muted color for the timestamp */
  font-size: 10px; /* Smaller font size */
  font-family: Arial, sans-serif; /* Clean sans-serif font */
  opacity: 0.8; /* Slightly more visible */
  padding: 2px 5px; /* Padding for a better look */
  z-index: 1; /* Ensure it appears above other elements */
  white-space: nowrap; /* Prevent text wrapping */
   
  @media (max-width: 827px) {
  top: -20px !important;
  }
  }
   
  .additional-buttons {
  margin-top: 15px; /* Add more space above buttons */
  display: flex;
  gap: 10px;
  justify-content: flex-start; /* Align buttons to the left */
  }
   
  .additional-buttons button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  transition: color .2s ease, border-color .2s ease, background-color .3s ease;
  }
   
  .additional-buttons button:hover {
  background-color: #00244b;
  transform: translateY(-2px); /* Slight lift effect on hover */
  }
   
  .additional-buttons button:active {
  transform: translateY(0); /* Reset lift effect on click */
  }