/* src/styles/Header.css */
/*
.chat-header {
  display: flex;
  align-items: center; 
  background-image: url(../assets/headerBG.png) no-repeat center center / cover; 
  color: white;
  padding: 15px 20px; 
  max-width: 100%;
  justify-content: space-between; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}
 
.bot-img {
  height: 50px;
  margin-left: 10px; 
}
 
.chat-header-swiss {
  flex-grow: 1;
  margin-right: 250px;
}
 
.head {
  font-weight: 600;
  font-size: 1.8em;
  line-height: 1.2;
  color: var(--bs-heading-color);
  text-align: center;
  margin: 0; 
}
 
.user-img {
  height: 50px;
  width: 50px; 
  border-radius: 50%;
  object-fit: cover; 
  margin-right: 10px; 
}
 
.user-name {
  font-size: 1.1em; 
  font-weight: 500;
  align-self: center;
  margin-left: 10px; 
  color: #fff;
  white-space: nowrap; 
}
  
*/

/*////////////////////////Latestcode is below/////////////////////*/
/* src/styles/Header.css */
/* src/styles/Header.css */
.chat-header {
  display: flex;
  align-items: center; /* Center content vertically */
  background-image: url(../assets/headerBG.png) no-repeat center center / cover; /* Adjust image position and scaling */
  color: white;
  padding: 15px 20px; /* Increased padding for a more spacious look */
  max-width: 100%;
  justify-content: space-between; /* Space out elements evenly */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
  background-position: center center;
  background-repeat: no-repeat;
   
  @media (max-width: 768px) {
  display: inline !important;
  height: auto !important;
  padding: 10px 15px;
  }
  }
   
  .bot-img {
  height: 50px;
  margin-left: 10px; /* Reduced left margin for better alignment */
  @media (max-width: 768px) {
  height: 25px;
  }
  }
   
  .chat-header-swiss {
  flex-grow: 1; /* Allow this section to grow and push content evenly */
  margin-right: 250px; /* Adjusted to keep spacing balanced */
   
  @media (max-width: 768px) {
  margin-right: 0px;
  }
  }
   
  .head {
  font-weight: 600;
  font-size: 1.8em;
  line-height: 1.2;
  color: var(--bs-heading-color);
  text-align: center;
  margin: 0; /* Ensure no extra margins */
   
  @media (max-width: 768px) {
  font-size: 1.6em;
  }
  }
   
  .head:after {
  /* {content: "IT Helpdesk Chat"; */
   
  @media (max-width: 768px) {
  font-size: 1.6em;
 /* {} content: "IT Helpdesk Chat"; */
  }
  }
   
  .user-img {
  height: 50px;
  width: 50px; /* Ensure the image remains a perfect circle */
  border-radius: 50%;
  object-fit: cover; /* Ensure image scales properly without distortion */
  margin-right: 10px; /* Add margin for better spacing */
  }
   
  .user-name {
  font-size: 1.1em; /* Slightly increase font size for better readability */
  font-weight: 500;
  align-self: center;
  margin-left: 10px; /* Add margin for spacing next to the image */
  color: #fff; /* Ensure text contrasts well with the background */
  white-space: nowrap; /* Prevent the name from breaking into multiple lines */
  }