/* Card.css */
.card {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px 0;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .card h3 {
    margin: 0 0 5px;
    font-size: 16px;
  }
  
  .card p {
    margin: 0;
    font-size: 14px;
  }
  