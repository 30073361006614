/* Background Styling */
body,
html {
    height: 100%;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(135deg, #74ebd5, #acb6e5);
    display: flex;
    align-items: center;
    justify-content: center;
}
 
.report-page {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: fadeIn 1s ease-in-out;
    max-width: 450px;
    width: 90%;
}
 
h2 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #333;
}
 
.report-page .date-input {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 12px 0;
}
 
.report-page label {
    font-weight: bold; /* Make the label bold */
    margin-right: 10px; /* Space between label and input */
    flex: 0 0 15%; /* Adjust width of label */
    text-align: right; /* Align text to the right */
}
 
.report-page input[type="date"] {
    width: 70%; /* Adjust width of input to fit beside label */
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}
 
.report-page input[type="date"]:focus {
    border-color: #0078d4;
    background-color: #eaf4ff;
    outline: none;
}
 
.report-page button {
    width: 100%;
    max-width: 400px;
    padding: 12px 16px;
    margin: 10px 0;
    border: none;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #0078d4;
    box-shadow: 0 4px 12px rgba(0, 120, 212, 0.4);
}
 
.report-page button:hover {
    background-color: #005fa3;
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 95, 163, 0.5);
}
 
.report-page button.secondary {
    background-color: #f44336;
    box-shadow: 0 4px 12px rgba(244, 67, 54, 0.4);
}
 
.report-page button.secondary:hover {
    background-color: #d32f2f;
}
 
/* Add subtle animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
 
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
 
.report-page .message-box {
    margin-top: 20px;
    padding: 12px 16px;
    font-size: 16px;
    border-radius: 8px;
    color: #ffffff;
    text-align: center;
    animation: fadeIn 0.8s ease-in-out;
}
 
.report-page .message-box.success {
    background-color: #4caf50;
}
 
.report-page .message-box.error {
    background-color: #f44336;
}
 
.report-page .message-box.info {
    background-color: #0078d4;
}