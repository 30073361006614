/* src/components/Chatbot.css */
.chatbot {
  width: 100%;
  /* max-width: 600px; */
  margin: 0 auto;
}

.chat-window {
  border: 4px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 500px;
}

.messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: pre-wrap;
}

.input-form {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  box-sizing: border-box;
}

textarea {
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
  box-sizing: border-box;
}

button {
  padding: 10px;
  margin-left: 10px;
  margin-bottom: 5px;
  border: none;
  background-color: #0056b3;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030;
}

.language-selector {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  max-width: 500px;
  margin-left: -4px;
}

.language-btn {
  min-width: 100px;
  padding: 15px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030;
  cursor: pointer;
  margin: 5px;
  padding: 10px;
}

.language-btn:hover {
  background-color: #00244b;
}

.language-btn.selected {
  background-color: green;
  color: white;
}

.language-btn:disabled {
  background-color: darkgray;
  cursor: not-allowed;
  opacity: 0.5;
}

.selected-language-display {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f1f1f1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: bold;
}

.message {
  margin-bottom: 10px;
}

.additional-buttons {
  margin-top: 10px;
  display: block;
  gap: 10px;
  background-color: transparent !important;
  border-radius: 20px;
  /* box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030; */
}

.additional-buttons button {
  min-width: 100px;
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 15px;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030;
}

.additional-buttons button:hover {
  background-color: #00244b;
}

/* Existing styles... */

.no-kb-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  background-color: transparent !important;
  border-radius: 20px;
  /* box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030; */
}

.no-kb-buttons button {
  min-width: 150px;
  padding: 15px;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030;
}

.no-kb-buttons button:last-child {
  margin-bottom: 5px;
  /* Remove margin from the last button */
}

.my-tickets-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  background-color: transparent !important;
  border-radius: 20px;
  /* box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030; */
}

.my-tickets-buttons button {
  min-width: 100px;
  padding: 15px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030;
  cursor: pointer;
}

.my-tickets-buttons button:last-child {
  margin-bottom: 5px;
  /* Remove margin from the last button */
}

.create-incident {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.create-incident button {
  min-width: 100px;
  padding: 15px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030;
  cursor: pointer;
}

.create-incident button:last-child {
  margin-bottom: 5px;
  /* Remove margin from the last button */
}

.dropdown-list {
  background-color: transparent !important;
  max-width: 500px !important;
  border-radius: 10px;
  margin-bottom: 0 !important;
  overflow-x: hidden;
}

.dropdown-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}

.subcategory-list {
  margin-top: 10px;
}

.button-group {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  background-color: transparent !important;
  border-radius: 20px;
  /* box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030; */
}

.button-group button {
  padding: 10px 20px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030;
  cursor: pointer;
}

.button-group button:hover {
  background-color: #00244b;
}

.application-list ul {
  list-style-type: none;
  padding-left: 0;
  background-color: white;
  border: 1px solid #ccc;
  margin-top: 10px;
  max-height: 200px;
  /* Add a scrollbar if the list is long */
  overflow-y: auto;
}

.application-list li {
  padding: 5px;
  cursor: pointer;
}

.application-list li:hover {
  background-color: #f0f0f0;
}

/* Chatbot.css */

.card-buttons {
  display: flex;
  justify-content: left;
  margin-top: 10px;
 
  @media (max-width: 425px) {
    display: inline;
  }
}
 
.card-buttons button {
  padding: 8px 12px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-buttons button:hover {
  background-color: #00244b;
}

/* Container for the email input and button */
.email-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Styling for the email input field */
.email-input-container input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  width: 100%;
}

/* Styling for the submit button */
.email-input-container button {
  padding: 8px 12px;
  font-size: 14px;
  color: white;
  background-color: #0056b3;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  align-self: flex-end;
}

/* Button hover effect */
.email-input-container button:hover {
  background-color: #00244b;
}