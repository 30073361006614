/* src/App.css */
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  max-width: 100%;
  font-family: 'Arial', sans-serif;
}
 
.app-container {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
}
 
.chatbot {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-image: url(./assets/Background.jpg);
  background-size: cover; /* Make background cover full screen */
  background-position: center;
  overflow: hidden;
  /* filter: brightness(0.9);  */
}
 
.chat-window {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  margin: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9); /* Light background for better readability */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for a modern look */
  overflow: hidden;
}
 
.messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}
 
.input-form {
  display: flex;
  padding: 15px;
  border-top: 1px solid #ccc;
  background-color: #f8f9fa; /* Light background for input */
  position: sticky;
  bottom: 0;
  width: 100%;
}
 
textarea {
  resize: none;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #ccc;
  flex: 1;
  max-width: 85%; /* Better alignment */
  font-size: 16px;
  transition: border-color 0.3s ease; /* Add transition for focus effect */
}
 
textarea:focus {
  border-color: #0056b3; /* Change border color on focus */
}
 
button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #0056b3;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Added transform for scaling effect */
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 #0003,0 6px 20px 0 #00000030;
 
}
 
button:hover {
  background-color: #00244b;
  transform: scale(1.05);
}
 
/* src/styles/Header.css */
.chat-header {
  background-image: url(./assets/headerBG.png);
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  justify-content: center; /* Center content */
  align-items: center; /* Vertically center content */
  height: 40px; /* Give a consistent header height */
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  border-bottom: 2px solid #0056b3; /* Add a bottom border for separation */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added shadow for separation */
}
 
/* src/styles/Message.css */
.message {
  padding: 10px;
  position: relative;
  margin: 10px 0; /* Add spacing between messages */
  margin-bottom: 10px;
  border-radius: 20px;
  max-width: fit-content; /* Fit according to text */
  box-shadow: 1px 7px 9px 2px #0000001a; /* Soft shadow for a card-like look */
  word-wrap: break-word;
  transition: transform 0.2s ease; /* Added transition for animation effect */
 
  @media (max-width: 425px) {
    font-size: 14px;
  }
}
 
.message.user {
  background-color: #c7dbff;
  align-self: flex-end;
  float: right;
  clear: both;
}
 
.clear-float{
  clear: both;
}
 
.message.user:after {
  clear: both;
  height: 0;
  width: 100%;
  content: '';
  display: block;
}
 
.message.bot {
  background-color: #a67fe8; /* for yellow #e2fc8a */
  color: black;
  align-self: flex-start;
  clear: both;
}
 
.warning-message {
  background-color: #0056b3; /* Yellow background */
  color: #fff;
  padding: 2px;
  text-align: center;
  font-weight: bold;
  position: relative; /* Optional for positioning */
  z-index: 1; /* Ensure it appears above other elements */
  white-space: nowrap;
  overflow: hidden;
}
 
.warning-message span {
  display: inline-block;
  animation: slide 30s linear infinite;
}
 
@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
 
/* styling emoji-button */
 
.feedback-options {
  display: flex;
  gap: 10px;
}
 
.emoji-button {
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
 
/* .emoji-button:hover {
  animation: bounce 0.5s ease infinite;
}
 
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
} */
 
.emoji-button:hover {
  background-color: #c7dbff;
  animation: wiggle 0.3s ease infinite;
}
@keyframes wiggle {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  75%{
    transform: rotate(-5deg);
  }
}
 
.feedback-smiley {
  width: 100%;  /* Adjust the size of your smiley */
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
 
/* .feedbackInput {
  width: 50%;
  white-space: pre-wrap;
  word-wrap: break-word;
}
 
.feedbackInput textarea {
  resize: vertical;
}
  */
/* .emoji-button {
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
} */
 
/* .emoji-button:hover .feedback-smiley {
  transform: scale(1.3);  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
} */
 
/* .emoji-button:hover::after {
  content: attr(title);
  display: block;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  color: #333;
} */
 
 
/* for pop of attachment */
 
/* .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
 
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}
 
.modal-buttons {
  margin-top: 20px;
}
 
.modal-buttons button {
  margin-right: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
 
.modal-buttons button:hover {
  background-color: #0056b3;
} */